/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
.card-element-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card-input-row label {
  margin-right: 10px;
}

.StripeElement {
  /* margin: 8px 0;
    padding: 10px 12px;
    color: #000;
    background-color: #fff;
    border: 1px solid lightgray;
    border-radius: 4px; */

    /* box-shadow: 0 1px 3px 0 #e6ebf1; */
    /* -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease; */
  }

  .StripeElement--focus {
    /* box-shadow: 0 1px 3px 0 #cfd7df; */
  }

  .StripeElement--invalid {
    /* border-color: #fa755a; */
  }

  .StripeElement--webkit-autofill {
    background-color: inherit !important;
  }